.EditEventButton {
  float: right;
  position: absolute;
  right: 0px;
  top: 0px;
}

.Editor {
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
}

.EditorControls {
  display: flex;
  flex-direction: column;
}

.EditorText {
  width: 100%;
}

.EventCard {
  border-color: grey;
  border-style: solid;
  border-width: 1px;
  margin: 3%;
  padding: 2%;
}

.EventDescriptionDiv {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0%;
  text-align: left;
}

.EventDetails {
  align-items: center;
  display: flex;
  flex-direction: column;;
  justify-content: center;
  width: 100%;
}

.Undecided {
  margin: 2%;
  border: 5px solid cyan;
  animation: border-pulsate 5s infinite;
}

.VenueOptionsLayout {
  align-content: center;
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  width: 100%;
}

@keyframes border-pulsate {
  0%   { border-color: rgba(0, 255, 255, 1); }
  50% { border-color: rgba(0, 255, 255, 0); }
  100%   { border-color: rgba(0, 255, 255, 1); }
}