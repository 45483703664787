.AppHeader {
  background-color: white;
  color: black;
  height: 30%;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  align-items: center;
  position: relative;
  width: 100%;
}

.AppHeaderButton {
  margin: 2%;
}

.AppHeaderFilter {
  margin: 2%;
  width: 50%;
}

.LogoImg {
  height: auto;
  width: 100%;
}

.LogoHref {
  display: inline-block;
  max-width: 35%;
  padding: 2%;
}

.SettingsMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 4%;
}

.UserNameNotice {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1%;
}
