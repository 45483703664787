.InterestIndicatorDiv {
  height: 75%;
  width: 96%;
  margin: 2%;
  overflow: hidden;
  border-radius: 5px;
}

.RSVPNegative {
  background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,255,0) 100%);
  height: 100%;
  transition: width 0.3s linear;
}

.RSVPMaybe {
  background-color: white;
  height: 100%;
  transition: width 0.3s linear;
}

.RSVPPositive {
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,204,0,1) 100%);
  height: 100%;
  transition: width 0.3s linear;
}
