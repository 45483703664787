.DateTimesDiv {
  margin-top: 3%; 
  padding-right: 2%;
}

.DateTimeText {
  padding-left: 2%;
  padding-right: 2%;
}

