.rideShareListings {
  display: flex;
  gap: 3%;
  flex-grow: 1;
  flex-shrink: 1;
}

.rideShare {
  display: flex;
  gap: 3%;
  flex: 1;
}

.rideShareSide {
  display: flex;
  flex-direction: column;
  flex: 1;
}