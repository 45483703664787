.App {
  text-align: center;
}

.LoginBox {
  padding: 5%;
}

.LoginInstructionsBox {
  padding-top: 5%;
}